import React from 'react'

import pageContext from './pageContext.json'

import Layout from 'src/layouts/BaseLayout'
import Hero from '../../politica-de-privacidade/sections/hero/_index'
import PrivacyPolicyPage from '../../politica-de-privacidade/sections/politica-de-privacidade/_index'

const PrivacyPolicy = () => {
  const lang = 'en'

  return (
    <Layout pageContext={pageContext}>
      <Hero lang={lang} />
      <PrivacyPolicyPage lang={lang} />
    </Layout>
  )
}

export default PrivacyPolicy
